<template>
  <div class="subscription-layout">
    <div class="subscription-layout__content">
      <slot />
    </div>

    <footer class="subscription-layout__footer footer">
      <div class="footer__wrapper ui-container">
        <div class="footer__contacts contacts">
          <ul class="contacts__list">
            <li class="contacts__item contacts__item--email">
              <a
                href="mailto:hello@skillbox.ru"
                class="contacts__email t t--2 t--m"
              >
                hello@skillbox.ru
              </a>

              <span class="contacts__text p p--5">
                Вопросы и предложения
              </span>
            </li>

            <li class="contacts__item">
              <address class="contacts__address t t--5">
                119049, г. Москва, Ленинский пр-т, д.&nbsp;6,<br> стр.&nbsp;20, этаж&nbsp;3, ком.&nbsp;4
              </address>

              <a
                href="https://yandex.ru/maps/-/CDC4yKOS"
                target="_blank"
                class="contacts__address-link"
              >
                Показать на карте
              </a>
            </li>
          </ul>
        </div>

        <div class="footer__docs docs">
          <ul class="docs__list">
            <li
              v-for="(item, i) in docs"
              :key="i"
              class="docs__item"
            >
              <a
                :href="item.link"
                target="_blank"
                class="docs__link"
              >
                {{ item.text }}
              </a>
            </li>
          </ul>
        </div>

        <div class="footer__legal legal p p--5">
          <span
            class="legal__copyright"
            v-html="copyright"
          />
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
  import { useSubscribesStore } from '@academica-box/composables/store/subscribes'

  const route = useRoute()

  const subscribesStore = useSubscribesStore()

  const docs = ref([
    {
      text: 'Правила использования платформы',
      link: 'https://skillbox.ru/legal-docs/skillbox/file/terms_of_use/version-100322.pdf'
    },
    {
      text: 'Политика конфиденциальности',
      link: 'https://skillbox.ru/legal-docs/chou/file/privacy_policy/version-030724.pdf'
    },
    {
      text: 'Отменить подписку',
      link: 'https://my.cloudpayments.ru/'
    }
  ])

  const getOffer = (link: string) => {
    docs.value.unshift({
      text: 'Оферта',
      link
    })
  }

  const copyright = computed(() => {
    const year = new Date().getFullYear()

    return `© Skillbox, ${year}`
  })

  switch (route.path.replaceAll('/', '')) {
  case 'dev-skillbox-pro':
    getOffer('https://cdn.skillbox.pro/wbd-front/academica-static/subscription-page/dev-skillbox-pro/akademika_program2.pdf')
    break
  case 'skillbox-design-library':
    getOffer('https://cdn.skillbox.pro/wbd-front/academica-static/subscription-page/skillbox-design-library/oferta_design.pdf')
    break
  }

  useAsyncData(
    'getActiveSubscriptions',
    () => subscribesStore.getActiveSubscriptions(route.path.replaceAll('/', ''))
  )
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/combinations/media-hover-reset-z' as *;
  @use '@academica-box/styles/UiContainer/variables' as *;

  @mixin link-interaction {
    opacity: .7;
  }

  /* Layout */
  .subscription-layout {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100vw;
    min-height: 100vh;
  }

  .subscription-layout__content {
    grid-row: 1/2;
    grid-column: 1/-1;
  }

  .subscription-layout__footer {
    grid-row: -2/-1;
    grid-column: 1/-1;
  }
  /* End Layout */

  /* Footer */
  .footer {
    padding-top: 36px;
    padding-bottom: 36px;
    background-color: var(--bg-secondary-color);

    @include media(lg) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    @include media(xxl) {
      padding-top: 56px;
      padding-bottom: 56px;
    }
  }

  .footer__wrapper {
    display: grid;
    grid-row-gap: 40px;

    @include media(md, md-max) {
      padding-right: 12px;
      padding-left: 12px;
    }

    @include media(xxl) {
      grid-column-gap: 390px;
    }
  }

  .footer__docs {
    @include media(md) {
      grid-column: 2/3;
    }

    @include media(md, xl-max) {
      justify-self: end;
    }

    @include media(lg) {
      grid-row: 1/3;
    }
  }
  /* End Footer */

  /* Contacts */
  .contacts__list {
    @include ulres;

    @include media(lg) {
      display: flex;
    }
  }

  .contacts__item {
    &:not(:last-child) {
      @include media(md-max) {
        margin-bottom: 32px;
      }

      @include media(lg) {
        margin-right: 32px;
      }
    }

    &--email {
      width: 188px;
    }
  }

  .contacts__email {
    @include ui-motion(all link-highlight-main);

    display: block;
    margin-bottom: 4px;
    color: var(--text-main-color);

    @include media(lg) {
      font-size: 20px;
      line-height: 24px;
    }

    @include hover {
      @include link-interaction;
    }

    &:focus-visible {
      @include link-interaction;
    }
  }

  .is-safari .contacts__email:focus {
    @include link-interaction;
  }

  .contacts__text {
    display: block;
    color: var(--text-secondary-color);
  }

  .contacts__address {
    font-style: normal;
  }

  .contacts__address-link {
    color: inherit;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    text-decoration: underline;
    text-decoration-skip-ink: none;

    @include media(lg) {
      font-size: 12px;
      line-height: 16px;
    }

    @include hover {
      text-decoration: none;
    }

    &:focus-visible {
      text-decoration: none;
    }
  }

  .is-safari .contacts__address-link {
    text-decoration: none;
  }
  /* End Contacts */

  /* Docs */
  .docs__list {
    @include ulres;
  }

  .docs__item {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    text-decoration-skip-ink: none;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .docs__link {
    color: inherit;

    @include media(lg) {
      font-size: 12px;
      line-height: 16px;
    }

    @include hover {
      text-decoration: none;
    }

    &:focus-visible {
      text-decoration: none;
    }
  }

  .is-safari .docs__link {
    text-decoration: none;
  }
  /* End Docs */

  /* Legal */
  .legal {
    color: var(--text-secondary-color);
  }

  .legal__copyright {
    display: block;
  }

  .legal__text {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  }
  /* End Legal */
</style>
